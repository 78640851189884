
import { mapState } from "vuex";

import { PAGE_TYPE, QUERY_HOMEPAGE } from "./-index.query";
import { seoMixin, utagViewMixin, pageMixin } from "~/mixins";

const UID = "homepage";
const TEALIUM_EVENT = "home";

export default {
  mixins: [seoMixin, utagViewMixin, pageMixin],

  async asyncData({ app, error }) {
    const { [PAGE_TYPE]: data } = await app.$getPrismicData({
      query: QUERY_HOMEPAGE,
      variables: { uid: UID },
    });

    if (data?.slices == null ?? data?.slices?.length === 0) {
      return error({
        statusCode: 404,
        message: "Home page not found in the app",
      });
    }

    return {
      uid: UID,
      pageType: PAGE_TYPE,
      tealiumEvent: TEALIUM_EVENT,
      slices: data.slices,
      title: data.title,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      metaRobots: data.metaRobots,
    };
  },

  computed: {
    ...mapState("bag", ["miniCart"]),
  },

  mounted() {
    this.utagData = {
      cart_total_items: this.miniCart.lineItemsCount,
      cart_total_value: this.miniCart.totalPrice,
    };

    this.$optimizely.track({ type: "home" });
  },
};
