import { gql } from "graphql-tag";

import { getSeoFields, getMetaFields } from "~/mixins/seoMixin";
import { getListOfArticles } from "~/slices/ListOfArticles/fragment";
import { getCallToAction } from "~/slices/CallToAction/fragment";
import { getProductCatalogueV2 } from "~/slices/ProductCatalogueV2/fragment";
import { getNewsSubscription } from "~/slices/NewsSubscription/fragment";
import { getTopCategory } from "~/slices/TopCategory/fragment";
import { shopByStyle } from "~/slices/ShopByStyle/fragment";
import { instagram } from "~/slices/Instagram/fragment";
import { heroCarousel } from "~/slices/HeroCarousel/fragment";
import { customerReview } from "~/slices/CustomerReview/fragment";
// import { getYotpoWidget } from "~/slices/YotpoWidget/fragment";

const PAGE_TYPE = "Homepage";

const QUERY_HOMEPAGE = gql`
  query homepage($uid: String!, $lang: String!, $lazy: Boolean = false) {
    ${PAGE_TYPE}:homepage(uid: $uid, lang: $lang) {
        title
      ...${PAGE_TYPE}SEOFields
      ...${PAGE_TYPE}MetaFields
      slices: body {
        ...${PAGE_TYPE}_hero_carousel
        ...${PAGE_TYPE}_product_catalogue_v2
        ...${PAGE_TYPE}_instagram
        ...${PAGE_TYPE}_customer_review
        ...${PAGE_TYPE}_top_category
        ...${PAGE_TYPE}_call_to_action
        ...${PAGE_TYPE}_shop_by_style
        ...${PAGE_TYPE}_list_of_articles
        ...${PAGE_TYPE}_news_subscription
      }
    }
  }
  ${getListOfArticles(PAGE_TYPE)}
  ${getProductCatalogueV2(PAGE_TYPE)}
  ${getSeoFields(PAGE_TYPE)}
  ${getMetaFields(PAGE_TYPE)}
  ${getCallToAction(PAGE_TYPE)}
  ${getNewsSubscription(PAGE_TYPE)}
  ${getTopCategory(PAGE_TYPE)}
  ${shopByStyle}
  ${instagram}
  ${heroCarousel}
  ${customerReview}
`;

export { QUERY_HOMEPAGE, PAGE_TYPE };
