import { gql } from "graphql-tag";

import { documentLink, collectionLink } from "~/app/prismic/prismic-fragments";

export const getTopCategory = pageType => gql`
  fragment ${pageType}_top_category on ${pageType}BodyTop_category {
    type
    label
    primary {
      title
    }
    fields {
      link {
        ... documentLink
        ... collectionLink
      }
    }
  }
 ${documentLink}
 ${collectionLink}`;
